<template>
  <div>

    <b-tabs content-class="col-12 mt-1 mt-md-0" nav-wrapper-class="col-12" nav-class="nav-left">
      <!--  inicidencias -->
      <b-tab active>
        <template #title>
          <feather-icon icon="ActivityIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Listado de incidencias</span>
        </template>

        <b-card no-body>
          <!-- filtros -->
          <b-row
            align-h="between"
            align-v="center"
            class="px-1 pt-2 pb-1"
          >
            <b-col cols="auto">
              <h3 class="m-0">
                Incidencias
              </h3>
            </b-col>
            <b-col cols="auto" class="d-flex align-items-center justify-content-end">
              <b-link
                :to="{
                  name: 'createIncidence',
                }"
              >
                <b-button variant="primary" size="sm" class="mr-1 text-nowrap">
                  {{ "+ Crear Incidencia" }}
                </b-button>
              </b-link>
            </b-col>
          </b-row>
          <hr class="m-0">
          <div class="p-1">
            <div class="col-12">
              <b-row>
                <b-col class="col-11"></b-col>
                <b-col class="col-1 text-right">
                  <span class="cursor-pointer" @click="visibleFilter = !visibleFilter">
                    <feather-icon icon="FilterIcon" size="20" />
                  </span>
                </b-col>
              </b-row>
            </div>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col sm="12" md="4">
                    <b-form-group label-for="filter-client">
                      <BaseSelect
                        :resource="'/projects'"
                        @input="project = $event"
                        :value="project"
                        :label="'name'"
                        :placeholder="'Filtrar por obra'"
                        :clearable="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="4">
                    <flat-pickr v-model="date" name="date" class="form-control" :config="dateConfig"
                      :placeholder="'Filtrar por fecha'" />
                  </b-col>
                  <b-col sm="12" md="4">
                    <div class="d-flex justify-content-start align-items-center">
                      <b-form-input v-model="searchTerm" :placeholder="$t('Busqueda')" type="text"
                        class="d-inline-block" />
                      <b-button class="ml-1" @click="handleSearch" variant="primary">
                        {{ $t("Buscar") }}
                      </b-button>
                    </div>
                  </b-col>
                  <b-col sm="12" md="4">
                    <b-form-group label-for="filter-client">
                      <SelectClients v-model="client" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <!-- table -->
          <b-table hover responsive :items="items" :fields="seleccionarcolumnas" class="table-white">
            <template #cell(client)="data">
              <b-link :to="{ name: 'viewClient', params: { id: data.item.client.id } }" class="link">
                {{ data.item.client.name }}
              </b-link>
            </template>
            <template #cell(project)="data">
              <b-link v-if="data.item.project" :to="{ name: 'viewProject', params: { id: data.item.project.id } }"
                class="link">
                {{ data.item.project.name }}
              </b-link>
            </template>
            <template #cell(user)="data">
              <b-link :to="{ name: 'viewUser', params: { id: data.item.user.id } }" class="link">
                {{ data.item.user.name }} {{ data.item.user.surname }}
              </b-link>
            </template>
            <template #cell(date)="data">
              <span>{{ dateColumn(data.item.date) }}</span>
            </template>
            <template #cell(name)=data>
              <b-link :to="{ name: 'viewIncidence', params: { id: data.item.id } }" class="link">
                <span>{{ (data.item.name.length > 30) ? data.item.name.substring(0, 30) + "..." : data.item.name
                }}</span>
              </b-link>

            </template>
            <template #cell(apto)="data">
              <b-badge class="px-2" :variant="statusIncidenceVariants[data.item.apto]" style="top: -3px">
                {{ statusIncidenceTexts[data.item.apto] }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link :to="{ name: 'viewIncidence', params: { id: data.item.id } }"
                  class="d-inline-block px-50 text-dark">
                  <feather-icon icon="EyeIcon" />
                </b-link>
                <span v-if="currentUser.roles[0].name === 'super_admin' && currentUser.id === data.item.user.id">
                  <b-link :to="{ name: 'editIncidence', params: { id: data.item.id } }"
                    class="d-inline-block px-50 text-dark">
                    <feather-icon icon="Edit2Icon" />
                  </b-link>
                </span>
                <span
                  class="d-inline-block px-50 text-danger"
                  @click="deleteIncidence(data.item)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
          <!-- pagination -->
          <div v-if="totalItems > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select v-model="pageLength" :options="pages" class="ml-50 mr-1" @input="handlePageChange" />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalItems }}</span>
            </div>
            <div>
              <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="handleChangePage">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>
      </b-tab>
      <!-- alertas -->
      <b-tab>
        <template #title>
          <feather-icon icon="AlertTriangleIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Listado de Alertas</span>
        </template>

        <b-card no-body>
          <!-- filters -->
          <div class="p-1">
            <div class="col-12">
              <b-row>
                <b-col class="col-11">

                </b-col>
                <b-col class="col-1 text-right">
                  <span class="cursor-pointer" @click="(visibleFilter2 = !visibleFilter2)">
                    <feather-icon icon="FilterIcon" size="20" />
                  </span>
                </b-col>
              </b-row>
            </div>
            <b-collapse id="collapse" v-model="visibleFilter2" class="mt-2">
              <div class="px-2">
                <b-row>

                  <b-col cols="4">
                    <b-form-group label-for="filter-status">
                      <v-select v-model="tipo" label="alias" :filterable="true" :searchable="true"
                        :options="selectAlertTypes" :placeholder="'Buscar por Tipo'" />
                    </b-form-group>
                  </b-col>

                </b-row>
              </div>
            </b-collapse>
          </div>
          <!-- table -->
          <b-table hover responsive :fields="seleccionarcolumnas2" :items="alerts" class="table-white">
            <template #cell(date)="data">
              <span>{{ dateColumn(data.item.date) }}</span>
            </template>
            <template #cell(apto)="data">
              <b-badge class="px-2" :variant="statusIncidenceVariants[data.item.status]" style="top: -3px">
                {{ statusIncidenceTexts[data.item.status] }}
              </b-badge>

            </template>
          </b-table>
          <!-- pagination -->
          <div v-if="totalItems > pageLength" class="card-footer list-footer d-flex align-items-center justify-content-between mb-1 px-2">
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> {{ "Por página" }}: </span>
              <b-form-select v-model="pageLengthAlert" :options="pagesAlert" class="ml-50 mr-1"
                @input="handleChangePageAlert" />
              <span class="text-nowrap"> {{ "Total" }}: {{ totalAlerts }}</span>
            </div>
            <div>
              <b-pagination v-model="currentPageAlert" :total-rows="totalAlerts" :per-page="pageLengthAlert" first-number
                last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @change="handleChangePageAlert">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-card>

      </b-tab>
    </b-tabs>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BBadge, BPagination, BFormInput, BFormSelect, BInputGroup, BInputGroupPrepend, BButton, BCard, BTable, BCol, BRow, BLink, BCollapse, BFormGroup, BTabs, BTab,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";
import { config } from "@/shared/app.config";
import vSelect from "vue-select";
import flatPickr from 'vue-flatpickr-component'
import BaseSelect from '@/components/ui/select/BaseSelect.vue';
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import SelectClients from "@/components/clients/select/ClientsSelect.vue";

export default {
  components: {
    SelectClients,
    BCard,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BCollapse,
    vSelect,
    flatPickr,
    BFormGroup,
    BTabs,
    BTab,
    BaseSelect
  },
  data() {
    return {
      visibleFilter: false,
      visibleFilter2: false,
      statusIncidenceVariants: config.statusIncidenceVariants,
      statusIncidenceTexts: config.statusIncidenceTexts,
      currentPage: 1,
      pageLength: 10,
      pages: ["10", "15", "25"],
      searchTerm: "",
      searchTerm2: "",
      columns: [
        {
          label: 'Ref',
          key: 'ref',
        },
        {
          label: "Obra",
          key: "project",
        },
        {
          label: "Técnico",
          key: "user",
        },
        {
          label: "Nombre",
          key: "name",
        },
        {
          label: "Fecha",
          key: "date",
        },
        {
          label: "Estado",
          key: "apto",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      columns_admin: [
        {
          label: "Cliente",
          key: "client",
        },
        {
          label: "Obra",
          key: "project",
        },
        {
          label: "Técnico",
          key: "user",
        },
        {
          label: "Nombre",
          key: "name",
        },
        {
          label: "Fecha",
          key: "date",
        },
        {
          label: "Estado",
          key: "apto",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "column-actions",
        },
      ],
      tipo: '',
      currentPageAlert: 1,
      pageLengthAlert: 10,
      pagesAlert: ["10", "15", "25"],
      alertColumns: [
        {
          label: "Obra",
          key: "project.name",
        },
        {
          label: 'Equipo',
          key: 'asset.product_name',
        },
        {
          label: 'Fecha',
          key: 'date',
        },
        {
          label: "Estado",
          key: "apto",
        },

        {
          label: 'Tipo',
          key: 'type.alias',
        },
      ],
      alertColumnsAdmin: [
        {
          label: "Cliente",
          key: "client.name",
        },
        {
          label: "Obra",
          key: "project.name",
        },
        {
          label: 'Equipo',
          key: 'asset.product_name',
        },
        {
          label: 'Fecha',
          key: 'date',
        },
        {
          label: "Estado",
          key: "apto",
        },

        {
          label: 'Tipo',
          key: 'type.alias',
        },
      ],

      dateConfig: {
        mode: "range"
      },
      date: '',
      date_ini: '',
      date_fin: '',
      client: '',
      project: '',
    };
  },
  computed: {
    ...mapGetters({
      items: "incidences/getItems",
      totalItems: "incidences/getTotalItems",
      alerts: "alerts/getItems",
      totalAlerts: "alerts/getTotalItems",
      selectAlertTypes: 'alerts/getSelectAlertTypes',
      currentUser: 'auth/getUser',
    }),
    seleccionarcolumnas() {
      if (this.currentUser.roles[0].name === 'super_admin') {
        return this.columns_admin
      }
      return this.columns
    },
    seleccionarcolumnas2() {
      if (this.currentUser.roles[0].name === 'super_admin') {
        return this.alertColumnsAdmin
      }
      return this.alertColumns
    }
  },
  watch: {
    date() {
      let range = this.date.split(' a ')

      this.date_ini = FlatPickrToTimestamp(range[0]);

      if (range.length > 1) {
        this.date_fin = FlatPickrToTimestamp(range[1]);
      } else {
        this.date_fin = '';
      }

      this.currentPage = 1;
      this.chargeData();
    },
    client() {
      this.currentPage = 1;
      this.chargeData();
    },
    project() {
      this.currentPage = 1;
      this.chargeData();
    },
  },
  methods: {
    ...mapActions({
      list: "incidences/getListIncidences",
      getAlerts: "alerts/getAlerts",
      getSelectAlertTypes: 'alerts/getSelectAlertTypes',
      close: "incidences/close",
      delete: "incidences/delete"
    }),
    chargeData() {
      this.list({
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        date_ini: this.date_ini,
        date_fin: this.date_fin,
        id_client: this.client?.id || '',
        project_id: this.project?.id || '',
      });

      let tipo = this.tipo ? this.tipo.id : "";

      this.getAlerts({
        page: this.currentPageAlert,
        per_page: this.pageLengthAlert,
        type_id: tipo
      })
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePageAlert(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    dateColumn(time) {
      return TimestampToFlatPickr(time)
    },
    closeIncidence(id, name) {
      this.$bvModal
        .msgBoxConfirm(`¿Estás seguro de cerrar la incidencia ${name}?`, {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: "Cerrar Incidencia",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.close(id);
          }
        });
    },
    deleteIncidence(incidence) {
      this.$bvModal
        .msgBoxConfirm("¿Estás seguro de eliminar esta incidencia?", {
          bodyClass: 'bodyDelete',
          footerClass: 'footerDelete',
          okVariant: 'danger',
          okTitle: this.$t('Eliminar'),
          cancelTitle: this.$t('Cancelar'),
          cancelVariant: 'outline-danger',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.delete(incidence.id)
          }
        })
    }
  },
  async created() {
    await this.chargeData();
    await this.getSelectAlertTypes()
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
